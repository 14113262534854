@for $i from 1 through 12 {
  .col-#{$i} {
    width: calc(100% / 12 * $i - 30px);
  }

  .col-xs-#{$i} {
    @media (max-width: 600px) {
      width: calc(100% / 12 * $i - 30px) !important;
    }
  }
}

@function get-grid-breakpoint($key) {
  @return map-get($grid-breakpoints, $key);
}

@function get-theme-color($key) {
  @return map-get($theme-colors, $key);
}

@function get-theme-gradient($key) {
  @return map-get($theme-gradients, $key);
}

@function get-theme-font($key) {
  @return map-get($theme-fonts, $key);
}

@mixin media-breakpoint-down($key) {
  @media screen and (max-width: get-grid-breakpoint($key)) {
    @content;
  }
}

@mixin desktop($properties) {
  @each $property, $value in $properties {
    #{$property}: $value;
  }
}

@mixin mobile($properties) {
  $divider: 400px;

  @include media-breakpoint-down('xs') {
    @each $property, $value in $properties {
      #{$property}: $value;
    }

    @each $property, $value in $properties {
      @if (type-of($value) == 'list') {
        $list: ();

        @each $key in $value {
          @if (type-of($key) == 'number' and unit($key) == 'px') {
            $vw-value: $key / $divider * 100 + vw;
            $list: append($list, $vw-value);
          } @else {
            $list: append($list, $key);
          }
        }

        #{$property}: $list;
      } @else if (type-of($value) == 'number' and unit($value) == 'px') {
        #{$property}: $value / $divider * 100 + vw;
      }
    }
  }
}

@mixin row-flex($offset: 30px, $vertical_gap: false) {
  @include desktop(
    (
      display: flex,
      flex-wrap: wrap,
      margin-left: #{$offset / -2},
      margin-right: #{$offset / -2},
    )
  );

  @if ($vertical_gap) {
    @include desktop(
      (
        row-gap: $vertical_gap,
      )
    );
  }
}

@mixin col($offset: 30px) {
  @include desktop(
    (
      margin-left: #{$offset / 2},
      margin-right: #{$offset / 2},
    )
  );
}

@mixin input($height: 60px) {
  @include desktop(
    (
      display: block,

      width: 100%,
      height: $height,
      padding: 20px 25px,

      border: 1px solid get-theme-color('stroke'),
      border-radius: $input-radius,
      background: get-theme-color('background'),
    )
  );

  @include mobile(
    (
      padding: 20px,
      height: 40px,
    )
  );
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img,
abbr {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ul li {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
}
legend {
  color: #000;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

button {
  cursor: pointer;
  font: inherit;
}

// breakpoints
$grid-breakpoints: (
  'xs': 600px,
);

$theme-fonts: ();

$theme-colors: (
  'text': #001b4a,
  'text_secondary': #676d7b,
  'background': #f6f9fe,
  'stroke': #eef1f7,
  'white': #ffffff,
  'black': #001b4a,
  'primary': #3178ff,
  'warning': #e88818,
  'danger': #e01207,
  'success': #14ae5c,
);

$theme-gradients: ();

$card-radius: 20px;
$input-radius: 6px;
$transition-time: 0.3s;

:root {
  @each $name, $color in $theme-colors {
    --color-#{$name}: #{$color};
  }
}

.container {
  position: relative;
}

.fluid {
  @include input;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    margin-right: 5px;
  }

  svg {
    margin-left: auto;
    flex-shrink: 0;

    width: 13px;
    height: 7px;

    fill: get-theme-color('text');
  }
}

.droplist {
  z-index: 10;

  top: 100%;
  left: 0;
  right: 0;
}

.selected {
  background: get-theme-color('stroke');
}
